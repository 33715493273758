(function(){
var app ={

  //  navbarCollapse: function(){
  //     $('.navbar-nav>li>a').on('click', function(){
  //         $('.navbar-collapse').collapse('hide');
  //     });
  //  },
  navbarCollapse: function(){
    document.querySelectorAll('.navbar-nav > li > a').forEach((link) =>{
       link.addEventListener('click', (e) =>{
         document.querySelector('.navbar-collapse').classList.remove('show');
        });
    }); 
  },

  toggleAufZuClass: function(){

      // var $alleAufZus = $('a.aufzu');
      const alleAufZus = document.querySelectorAll('a.aufzu');
      // var $showDiv = $alleAufZus.filter(function () {
      //   return $($(this).attr('href')).hasClass('show');
      // });
      alleAufZus.forEach((az) =>{
         document.querySelector(az.hash).classList.remove('show'); 
      });
      // $showDiv.addClass('auf');
       // showDiv.classList.add('auf');

    // $('a.aufzu').on('click keypress', function () {
    //  $(this).toggleClass('auf');
    //  });
      document.querySelectorAll('a.aufzu').forEach((a) =>{
           a.addEventListener('click', (e) =>{
             a.classList.toggle('auf');
             document.querySelector(a.hash).classList.toggle('show');
           });
      });
  }


}

 app.navbarCollapse();
//  app.navbarScroll();
  app.toggleAufZuClass();


}());

